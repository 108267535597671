import { IS_UNDER_PRODUCTION } from "../../constants";

const FORWARDING_TABLE_PER_PAGE_KEY = "forwardingTablePerPage";

const IMPORT_SHIPMENT_INCOTERMS_LIST = [
  "FOB",
  "EXW",
  "FCA",
  "CFR",
  "CIF",
  "CPT",
  "CIP",
];

/**
 * 쉽다 풀필먼트 인천 제1센터의 zoneId
 * production 서버: 2760
 * dev 서버: 20294
 */
const ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1 = (() =>
  IS_UNDER_PRODUCTION ? 2760 : 20294)();

export {
  FORWARDING_TABLE_PER_PAGE_KEY,
  IMPORT_SHIPMENT_INCOTERMS_LIST,
  ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1,
};
