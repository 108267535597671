import { useParams } from "react-router-dom";
import { Box, Divider, SxProps, Theme, Typography } from "@mui/material";
import { BackTop } from "antd";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import CUSTOMS_ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_BID_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useCommentList from "@sellernote/_shared-for-forwarding-admin/src/hooks/useCommentList";
import BasicDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/BasicDesc";
import CargoInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/CargoInfo";
import ConsolidationCheckPointDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ConsolidationCheckPointDesc";
import ConsolidationEndAddress from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ConsolidationEndAddress";
import ConsolidationTotalInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ConsolidationTotalInfo";
import ExporterDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ExporterDesc";
import TransportationInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/TransportationInfo";
import WarehouseInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/WarehouseInfo";

import BidDetailTabs from "./BidDetailTabs";

const boxLayoutStyle: SxProps<Theme> = {
  p: 1,
  background: "#fff",
};

//TODO: 쉽다 어드민과 공용 컴포넌트로 변경
const BidDetail = () => {
  const { id }: { id: string } = useParams();

  const { data: bidDetail } = CUSTOMS_ADMIN_BID_QUERY.useGetCustomsBidDetail({
    bidId: Number(id),
  });

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: zoneList } = ADMIN_COMMON_QUERY.useGetZoneList();

  const { data: countryList } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: bidDetail?.locale,
  });

  const { CommentList } = useCommentList({
    commentList: bidDetail?.management.comments,
    dataId: bidDetail?.id,
    dataType: "bid",
  });

  if (
    !bidDetail ||
    !adminUserList ||
    !portList ||
    !zoneList ||
    !countryList ||
    !exchangeRateList
  ) {
    return (
      <Layout breadcrumbs={["쉽다 의뢰"]} title={"의뢰 상세"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["십다 의뢰"]} title={"의뢰 상세"}>
      <BackTop />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography variant="h6">기본 정보</Typography>

          <BasicDesc bidDetail={bidDetail} adminUserList={adminUserList} />

          <Divider sx={{ my: 1 }} />
        </Box>

        {bidDetail.serviceType !== "consolidation" && (
          <Box sx={boxLayoutStyle}>
            <TransportationInfo
              bidDetail={bidDetail}
              portData={portList}
              zoneData={zoneList}
              countryList={countryList}
            />

            <Divider sx={{ my: 1 }} />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {bidDetail.serviceType === "consolidation" && (
            <>
              <Box sx={boxLayoutStyle}>
                <Typography variant="h6">수출자 및 화물 정보</Typography>

                <CargoInfo bidDetail={bidDetail} />
              </Box>
            </>
          )}

          {bidDetail.serviceType !== "consolidation" && (
            <>
              <Box sx={boxLayoutStyle}>
                <ExporterDesc bidDetail={bidDetail}></ExporterDesc>

                <Divider sx={{ my: 1 }} />
              </Box>
            </>
          )}

          <Box sx={boxLayoutStyle}>{CommentList} </Box>

          {bidDetail.serviceType === "consolidation" && (
            <Box sx={boxLayoutStyle}>
              <ConsolidationTotalInfo bidDetail={bidDetail} />

              <Divider sx={{ my: 1 }} />
            </Box>
          )}

          {bidDetail.serviceType === "consolidation" && (
            <Box sx={boxLayoutStyle}>
              <ConsolidationCheckPointDesc bidDetail={bidDetail} />

              <Divider sx={{ my: 1 }} />
            </Box>
          )}

          {bidDetail.serviceType === "consolidation" && (
            <Box sx={boxLayoutStyle}>
              <ConsolidationEndAddress bidDetail={bidDetail} />
              <Divider sx={{ my: 1 }} />
            </Box>
          )}

          {bidDetail.serviceType === "consolidation" && (
            <Box sx={boxLayoutStyle}>
              <WarehouseInfo bidDetail={bidDetail} />

              <Divider sx={{ my: 1 }} />
            </Box>
          )}

          <Box sx={boxLayoutStyle}>
            <BidDetailTabs bidDetail={bidDetail} />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default withRequireAuth(BidDetail);
