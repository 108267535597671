import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import {
  PartnerTrelloList,
  TrelloIssueFilter,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { checkIsInlandFarePaymentRequest } from "@sellernote/_shared/src/utils/forwarding/trello";

import usePartnerCardListData from "../hooks/usePartnerCardListData";
import { getServiceType } from "../utils";

import TrelloCardList from "../components/TrelloCardList";
import TrelloHeaderFilter from "../components/TrelloHeaderfilter";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../jotaiStates/auth";
import PartnerAdminTrelloDetail from "../TrelloDetail/PartnerAdminTrelloDetail";

const Partner = ({
  trelloListData,
  adminData,
}: {
  trelloListData: PartnerTrelloList[];
  adminData: ForwardingAdminUserListItem[];
}) => {
  const trelloCardId = useRecoilValue(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const history = useHistory();

  const { pathname } = useLocation();

  const serviceType = getServiceType({ pathname });

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [sessionStorageTrelloIssueFilter, setSessionStorageTrelloIssueFilter] =
    useSessionStorage<TrelloIssueFilter | null>("trelloIssueFilter");

  const [showModal, setShowModal] = useState(false);
  const [bidId, setBidId] = useState(0);
  const [showCheckIsPaymentModal, setShowCheckIsPaymentModal] = useState(false);
  const [cardEndAddress, setCardEndAddress] = useState<null | string>(null);

  const openTrelloCardDetail = useCallback((bidId: number) => {
    setBidId(bidId);
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (trelloCardId) {
      openTrelloCardDetail(trelloCardId);
    }
  }, [history.action, openTrelloCardDetail, trelloCardId]);

  const handleTrelloCardClick = useCallback(
    (cardData: PartnerTrelloList) => {
      if (
        APP_NAME === "partner-admin" ||
        /** 정산 담당자가 통관 완료된 의뢰의 정산을 처리하지 못하게 막음 */
        currentAdminAuthInfo?.authority === "finance" ||
        serviceType === "export"
      ) {
        openTrelloCardDetail(cardData.id);
        return;
      }

      if (checkIsInlandFarePaymentRequest(cardData)) {
        setShowCheckIsPaymentModal(true);
        setBidId(cardData.id);
        setCardEndAddress(cardData.endAddress);
        return;
      }

      openTrelloCardDetail(cardData.id);
    },
    [currentAdminAuthInfo?.authority, openTrelloCardDetail, serviceType]
  );

  const { partnerCardListData } = usePartnerCardListData({
    trelloListData,
    handleTrelloCardClick,
    sessionStorageTrelloIssueFilter,
  });

  const salesNameList = useMemo(() => {
    return (
      adminData &&
      adminData.filter((v: ForwardingAdminUserListItem) => {
        return v.isForwardingManager === true;
      })
    );
  }, [adminData]);

  const handleSessionStorageTrelloIssueFilterChange = (
    value: TrelloIssueFilter | null
  ) => {
    setSessionStorageTrelloIssueFilter(value);
  };

  return (
    <div>
      <TrelloHeaderFilter
        salesNameList={salesNameList}
        sessionStorageTrelloIssueFilter={sessionStorageTrelloIssueFilter}
        onSessionStorageTrelloIssueFilterChange={
          handleSessionStorageTrelloIssueFilterChange
        }
      />

      <TrelloCardList cardListData={partnerCardListData} />

      {showModal && (
        <PartnerAdminTrelloDetail
          showModal={showModal}
          setShowModal={setShowModal}
          bidId={bidId}
        />
      )}
    </div>
  );
};

export default Partner;
